import { useCallback, useEffect, useState } from "react";
import useDetectUserInteraction from "./useDetectUserInteraction";

const KEY_GEO_LIFETIME = "geo-lifetime";
const KEY_REPEAT_DELAY = 15 * 60 * 1000; //15 minutes in ms

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const useGeo = () => {
  const [processing, setProccessing] = useState(true);
  const [coords, setCoords] = useState<any>(null);
  const ready = useDetectUserInteraction();

  const success = useCallback((position: GeolocationPosition) => {
    setCoords(position.coords);

    setProccessing(false);
  }, []);

  const error = useCallback((err: GeolocationPositionError) => {
    console.log(
      `Error geolocation.getCurrentPosition (${err.code}): ${err.message}`
    );
    setProccessing(false);
  }, []);

  const setCurrentTimestamp = useCallback((timestamp: number) => {
    localStorage.setItem(KEY_GEO_LIFETIME, `${timestamp}`);
  }, []);

  useEffect(() => {
    if (!ready) {
      return;
    }

    const currentTimestamp = Date.now();
    const timestamp = localStorage.getItem(KEY_GEO_LIFETIME);
    if (timestamp) {
      const lastTimestamp = parseInt(timestamp);
      if (!Number.isNaN(lastTimestamp)) {
        if (currentTimestamp - lastTimestamp < KEY_REPEAT_DELAY) {
          return
        } else {
          setCurrentTimestamp(currentTimestamp);
        }
      } else {
        setCurrentTimestamp(currentTimestamp);
      }
    } else {
      setCurrentTimestamp(currentTimestamp);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }, [error, ready, setCurrentTimestamp, success]);

  return { processing, coords } as {
    processing: boolean;
    coords: GeolocationCoordinates;
  };
};

export default useGeo;
